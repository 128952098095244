// Load CSS Reset from NPM
// @import "normalize.css";
// @import "./odometer.css";
// @import "./asset-cart.scss";
@import "./modal-video.scss";

/* CSS Variables */
:root {
    --green: #00A543;
    --orange: #FFB238;
    --blue: #3E86C4;
    --burnt: #DC783A;
    --red: #cd040b;
}

$red: var(--red);

// Load CSS Reset from NPM
@import "normalize.css";
@import "./odometer.css";
@import "./asset-cart.scss";

// Fonts
@font-face {
    font-family: 'Verizon NHG DS';
    src: url('./fonts/VerizonNHGDS-Bold.woff2') format('woff2'),
        url('./fonts/VerizonNHGDS-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Verizon NHG DS';
    src: url('./fonts/VerizonNHGDS-BoldItalic.woff2') format('woff2'),
        url('./fonts/VerizonNHGDS-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Verizon NHG DS';
    src: url('./fonts/VerizonNHGDS-Bold.woff2') format('woff2'),
        url('./fonts/VerizonNHGDS-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Verizon NHG DS';
    src: url('./fonts/VerizonNHGDS-Italic.woff2') format('woff2'),
        url('./fonts/VerizonNHGDS-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Verizon NHG DS';
    src: url('./fonts/VerizonNHGDS-Regular.woff2') format('woff2'),
        url('./fonts/VerizonNHGDS-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Verizon NHG DS';
    src: url('./fonts/VerizonNHGDS-BoldItalic.woff2') format('woff2'),
        url('./fonts/VerizonNHGDS-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Verizon NHG TX';
    src: url('./fonts/VerizonNHGTX-Bold.woff2') format('woff2'),
        url('./fonts/VerizonNHGTX-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Verizon NHG DS';
    src: url('./fonts/VerizonNHGDS-Italic.woff2') format('woff2'),
        url('./fonts/VerizonNHGDS-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Verizon NHG TX';
    src: url('./fonts/VerizonNHGTX-BoldItalic.woff2') format('woff2'),
        url('./fonts/VerizonNHGTX-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Verizon NHG DS';
    src: url('./fonts/VerizonNHGDS-Regular.woff2') format('woff2'),
        url('./fonts/VerizonNHGDS-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Verizon NHG TX';
    src: url('./fonts/VerizonNHGTX-Bold.woff2') format('woff2'),
        url('./fonts/VerizonNHGTX-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Verizon NHG TX';
    src: url('./fonts/VerizonNHGTX-BoldItalic.woff2') format('woff2'),
        url('./fonts/VerizonNHGTX-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Verizon NHG TX';
    src: url('./fonts/VerizonNHGTX-Italic.woff2') format('woff2'),
        url('./fonts/VerizonNHGTX-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Verizon NHG TX';
    src: url('./fonts/VerizonNHGTX-Italic.woff2') format('woff2'),
        url('./fonts/VerizonNHGTX-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

///////////////////////////////////
// General Styles
///////////////////////////////////
html {
    position: relative;
    min-height: 100%;
    overflow-x: hidden;
}

body {
    padding-top: 64px;

    @media only screen and (max-width: 512px) {
        padding-top: 68px;
    }
}


//////////////////////
// Variables
//////////////////////

$green: #06AC4B;
$red: var(--red);

//////////////////////
// Mixins
//////////////////////
@mixin aspect-ratio($width, $height) {
    position: relative;

    &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: ($height / $width) * 100%;
    }

    >.content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

.sixteen-nine {
    @include aspect-ratio(16, 9);
}


// Fluid Type

@mixin fluid-type($font-min, $font-max, $screen-min, $screen-max) {
    font-size: #{$font-min}px !important;

    @media only screen and (min-width: #{$screen-min}px) {
        font-size: calc(#{$font-min}px + #{($font-max - $font-min)} * (100vw - #{$screen-min}px) / (#{$screen-max} - #{$screen-min})) !important;
    }

    @media only screen and (min-width: #{$screen-max}px) {
        font-size: #{$font-max}px !important;
    }
}

// Fluid LineHeight
@mixin fluid-lh($lh-min, $lh-max, $screen-min, $screen-max) {
    line-height: #{$lh-min}px !important;

    @media only screen and (min-width: #{$screen-min}px) {
        line-height: calc((#{$lh-min}px + #{($lh-max - $lh-min)} * (100vw - #{$screen-min}px) / (#{$screen-max} - #{$screen-min})) * 0.95) !important;
    }

    @media only screen and (min-width: #{$screen-max}px) {
        line-height: #{$lh-max}px !important;
    }
}



// Lazyload
.lazyload {
    opacity: 0;
    transition: all 500ms;
    background-repeat: no-repeat;
    background-size: cover;
}

.hqy-loaded {
    opacity: 1;
    transition: opacity .5s ease;
}

.lozad {
    opacity: 0;
    transition: all 500ms;
    background-repeat: no-repeat;
    background-size: cover;
}

.lozad[data-loaded="true"] {
    opacity: 1;
    transition: opacity .5s ease;
}

.img-fluid {
    max-width: 100%;
    ;
}

// Main Styles
//////////////////////
// Fonts & things
//////////////////////
h1 {
    font-family: 'Verizon NHG DS';
    @include fluid-type(35, 64, 400, 1450);
    @include fluid-lh(35, 64, 400, 1450);
    letter-spacing: .03em;
    margin: 0.25em 0;

    &.hero {
        @include fluid-type(50, 84, 400, 1450);
        @include fluid-lh(50, 84, 400, 1450);
        font-weight: bold;
    }
}

h1.hero span {
    display: inline-block;
}


h1.activate,
span.activate,
.activate {
    opacity: 0;

    // transform: translateY(60px);
    &.activated {
        opacity: 1 !important;
        // transform: translateY(0);
        transition: 1s ease;
    }
}

h2.bullet {
    font-family: 'Verizon NHG DS';
    @include fluid-type(30, 36, 400, 1450);
    font-weight: bold;
    color: black;
}

h3.subhead {
    font-family: 'Verizon NHG DS';
    @include fluid-type(22, 26, 400, 1450);
    // color: var(--green);
    margin-top: 0px;
    margin-bottom: 0em
}

p {
    font-size: 20px;
    font-family: 'Verizon NHG DS';
    line-height: 1.35em;

    &.lead {
        font-size: 24px;
        line-height: 1.5em;
        font-weight: 100;
    }

    @media only screen and (max-width: 767px) {
        font-size: 18px;
    }
}

.white-text {
    color: white;
}

.text-red,
.asset-text-red {
    color: #cd040b !important;
    font-size: 16px !important;
    display: none;
}

.green-text {
    // color: $green;
}

//////////////////////
// Buttons
//////////////////////
.white-btn,
.submit-button-style {
    display: inline-block;
    border: 2px solid white !important;
    border-radius: 50px;
    color: white;
    font-family: 'Verizon NHG DS';
    font-size: 21px;
    font-weight: 500;
    padding: 16px 40px;
    // line-height: 0;
    text-align: center;
    text-decoration: none;
    transition: all .25s ease;

    &:hover {
        transition: all .25s ease;
        background-color: white;
        color: black;
    }

    &.small {
        font-size: 18px;
        padding: 12px 28px
    }

    &.filled {
        background-color: #fff;
        color: #000;
        &:hover {
            transition: all .25s ease;
            background-color: black;
            color: white;
        }
    }

}

.white-link {
    color: white;
    text-decoration: none;
    font-family: 'Verizon NHG DS';
    font-weight: bold;
    font-size: 24px;
    padding-right: 34px;
    position: relative;
    color: white;
    transition: color .25s ease;
    display: inline-block;
    position: relative;

    &:after {
        content: '';
        height: 26px;
        width: 26px;
        position: absolute;
        right: -3px;
        // background-color: #3E86C4;
        background-image: url('../images/carrot-right-wht.svg');
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }

    &:hover {
        color: var(--green);
        transition: color .25s ease;

        &:after {
            background-image: url('../images/carrot-right-grn.svg');
        }
    }

    &.blk {
        &:hover {
            color: black;

            &:after {
                background-image: url('../images/carrot-right-blk.svg');
            }
        }
    }

    &.small {
        font-size: 16px;
        padding-right: 22px;

        &:after {
            content: '';
            height: 15px;
            width: 15px;
            position: absolute;
            right: 0px;
            background-image: url('../images/carrot-right-wht.svg');
        }
    }
}

.green-link {
    color: black !important;
    text-decoration: none;
    font-family: 'Verizon NHG DS';
    font-weight: bold;
    font-size: 24px;
    padding-right: 34px;
    position: relative;
    color: white;
    transition: color .25s ease;
    display: inline-block;
    position: relative;

    &:after {
        content: '';
        height: 26px;
        width: 26px;
        position: absolute;
        right: -3px;
        // background-color: #3E86C4;
        background-image: url('../images/carrot-right-blk.svg');
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }

    &:hover {
        color: black !important;
        transition: color .25s ease;

        &:after {
            background-image: url('../images/carrot-right-blk.svg') !important;
        }
    }

    &.small {
        font-size: 16px;
        padding-right: 22px;

        &:after {
            content: '';
            height: 15px;
            width: 15px;
            position: absolute;
            right: 0px;
            background-image: url('../images/carrot-right-blk.svg');
        }
    }
}


.hamburger {
    padding: 15px 15px 5px 15px;
    display: inline-block;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;

    @media only screen and (min-width: 767px) {
        display: none;
    }
}

.hamburger:hover {
    opacity: 0.7;
}

.hamburger.is-active:hover {
    opacity: 0.7;
}

.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
    background-color: #fff;
}

.hamburger-box {
    width: 40px;
    height: 24px;
    display: inline-block;
    position: relative;
}

.hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
    width: 40px;
    height: 4px;
    background-color: #fff;
    border-radius: 0;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
}

.hamburger-inner::before,
.hamburger-inner::after {
    content: "";
    display: block;
}

.hamburger-inner::before {
    top: -10px;
}

.hamburger-inner::after {
    bottom: -10px;
}


/*
   * Squeeze
   */
.hamburger--squeeze .hamburger-inner {
    transition-duration: 0.075s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--squeeze .hamburger-inner::before {
    transition: top 0.075s 0.12s ease, opacity 0.075s ease;
}

.hamburger--squeeze .hamburger-inner::after {
    transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--squeeze.is-active .hamburger-inner {
    transform: rotate(45deg);
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--squeeze.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.075s ease, opacity 0.075s 0.12s ease;
}

.hamburger--squeeze.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}


//////////////////////
// Grid
//////////////////////
.container {
    // max-width: 1480px;
    // margin: 0 auto;
    // padding-left: 15px;
    // padding-right: 15px;
}

//////////////////////
// Menu Nav
//////////////////////

.section-nav {
    position: fixed;
    top: 50%;
    left: 10px;
    transform: translate(-50%, -50%);
    z-index: 10;

    &.gray {
        li {
            a {
                // background-color: rgb(236, 18, 18);
                background-color: gray;
                transition: background-color .5s ease;
            }
        }
    }

    li {
        list-style-type: none;
        margin-bottom: 15px;
        position: relative;

        a {
            height: 30px;
            width: 3px;
            display: block;
            background-color: white;
            position: relative;
            transition: background-color .5s ease;

            &:after {
                content: '';
                // background-color: pink;
                position: absolute;
                width: 10px;
                height: 100%;
                top: 0;
                pointer-events: all;

            }

            &.active {
                background-color: $red;
            }
        }
    }

    @media only screen and (max-width: 768px) {
        display: none;
    }
}

//////////////////////
// Menu Bar
//////////////////////

nav.menu-bar {
    padding: 10px 30px;
    // width: 100%;
    height: auto;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    background-color: black;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    z-index: 15;

    @media only screen and (max-width: 767px) {
        padding: 10px 15px 10px 30px;
    }

    .logo-lockup {
        img {
            max-width: 100%;
            padding: 15px;
            width: 210px;
        }

        ;

        @media only screen and (max-width: 577px) {
            display: none;
        }
    }

    .button-wrap {
        display: flex;
        justify-content: center;
        align-items: center;

        @media only screen and (max-width: 767px) {
            .white-btn.small {
                padding: 7px 28px;
            }
        }

        @media only screen and (max-width: 577px) {
            width: 100%;
            justify-content: space-between;
        }
    }

    // @media only screen and (max-width: 600px) {
    //     flex-wrap: wrap; 
    //   }

}

//////////////////////
// Off canvas menu
//////////////////////

.offcanvas-menu {
    min-width: 300px;
    background-color: white;
    position: fixed;
    height: 100vh;
    top: 0;
    left: 0;
    transform: translateX(-100%);
    transition: transform .75s ease;
    display: flex;
    // justify-content: center;
    padding-left: 30px;
    align-items: center;
    z-index: 13;

    &.active {
        transform: translateX(0);
        transition: transform .75s ease;
    }

    a {
        color: black;
        display: block;
        font-size: 24px;
        text-decoration: none;
        font-family: 'Verizon NHG DS';
        margin-bottom: 10px;
        transition: color .25s ease;
        border-left: 2px solid transparent;
        padding-left: 20px;

        &:hover,
        &.active {
            // color: var(--green);
            border-left: 2px solid $red;
            transition: color .25s ease;
            font-weight: bold;
        }

        &.top {
            // color: var(--green);
        }
    }
}


//////////////////////
// Header
//////////////////////
#section0 {
    h1 {
        color: white;
    }

    .content-wrap {
        max-width: 940px;
    }
}



//////////////////////
// Masthead
//////////////////////
.masthead {
    position: relative;
    justify-items: left;
    display: grid;
    // grid-gap: 30px;
    grid-template-rows: 1fr;
    grid-template-columns: minmax(100px, 1fr) 40px minmax(100px, 1380px) minmax(60px, 1fr);
    grid-template-areas:
        '... ... masthead ...';
    background: black;
    background-size: cover;
    background-position: center;

    @media only screen and (max-width: 768px) {
        background-position: 75%;
    }

    h1 {
        color: white;
    }

    .logo {
        max-width: 360px;

        @media only screen and (max-width: 1000px) {
            max-width: 280px;
        }
    }

    .bg-grad {
        display: none;

        @media only screen and (max-width: 767px) {
            width: 100%;
            height: 100%;
            position: absolute;
            background-color: rgba($color: #000000, $alpha: 0.3);
            display: block;
        }
    }

    .container {
        grid-area: masthead;
        padding-top: 12%;
        padding-bottom: 15%;
        z-index: 1;
    }

    p {
        color: white;
        max-width: 75%;
        opacity: 0;

        &.active {
            opacity: 1;
            transition: opacity 1s ease;
        }
    }

    @media only screen and (max-width: 1000px) {
        grid-template-columns: 60px minmax(100px, 1fr) 40px;
        grid-template-areas:
            '... masthead ...';

        p {
            color: white;
            max-width: 90%;
        }
    }

    @media only screen and (max-width: 767px) {
        grid-template-columns: 30px minmax(100px, 1fr) 30px;
    }
}


//////////////////////
// Cloud contact center
//////////////////////

.cloud-contact-center {
    background-color: black;
    color: white;
    justify-items: left;
    display: grid;
    // grid-gap: 30px;
    grid-template-rows: 1fr;
    grid-template-columns: minmax(100px, 1fr) minmax(100px, 600px) minmax(100px, 680px) minmax(60px, 1fr);
    // grid-auto-rows: 680px;
    grid-template-areas:
        '... image content ...';
    padding-top: 5%;
    padding-bottom: 6%;

    .image-wrap {
        grid-area: image;
        width: 100%;
        background-position: center;
        @include aspect-ratio(600px, 656px);
    }

    .content-wrap {
        grid-area: content;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 15%;
    }

    @media only screen and (max-width: 767px) {
        grid-template-columns: 30px minmax(100px, 1fr) 30px;
        grid-template-areas:
            '... image ...'
            '... content ...';

        p {
            color: white;
            max-width: 90%;
        }

        .content-wrap {
            padding-left: 0;
        }
    }

}


//////////////////////
// A Complete Partnership
//////////////////////

.complete-partnership {
    justify-items: left;
    display: grid;
    // grid-gap: 30px;
    grid-template-rows: 1fr;
    grid-template-columns: minmax(100px, 1fr) 40px minmax(100px, 800px) 50px 50px minmax(100px, 376px) minmax(60px, 1fr);
    // grid-auto-rows: 680px;
    grid-template-areas:
        '... ... content ... line bullets ...';
    padding-top: 5%;
    padding-bottom: 6%;


    .line {
        grid-area: line;
        border-left: 2px solid black;
        height: 100%;
    }

    .content {
        grid-area: content;
        padding-right: 5%;

        p {
            padding-right: 60px;

            @media only screen and (max-width: 767px) {
                padding-right: 0px;
            }
        }
    }

    .bullets {
        grid-area: bullets;
        display: flex;
        flex-direction: column;

        h2 {
            margin-bottom: 0;
        }

        p {
            margin-top: 10px;
            max-width: 70%;
        }
    }

    @media only screen and (max-width: 1000px) {
        justify-items: left;
        display: grid;
        // grid-gap: 30px;
        grid-template-rows: 1fr;
        grid-template-columns: 70px minmax(100px, 1fr) 40px;
        // grid-auto-rows: 680px;
        grid-template-areas: '... content ...'
            '... bullets ...';

        padding-top: 5%;
        padding-bottom: 6%;

        .bullets {
            border-top: 2px solid black;
            flex-direction: row;
            flex-wrap: wrap;

            div {
                width: 50%;
            }
        }
    }

    @media only screen and (max-width:767px) {
        grid-template-columns: 30px minmax(100px, 1fr) 30px;

        .bullets {
            div {
                width: 100%;
            }
        }
    }


}



//////////////////////
// A Complete Solution
//////////////////////

.complete-solution {
    background-color: black;
    justify-items: center;
    display: grid;
    // grid-gap: 30px;
    grid-template-rows: 1fr;
    grid-template-columns: minmax(100px, 1fr) minmax(100px, 1380px) minmax(60px, 1fr);
    padding-top: 5%;
    padding-bottom: 6%;
    grid-template-areas:
        'contentwrap';

    @media only screen and (max-width:767px) {
        grid-template-columns: minmax(40px, 1fr) minmax(100px, 1380px) minmax(40px, 1fr);
    }

    .subhead {
        margin-bottom: 32px;
    }

    .content-wrap {
        grid-area: contentwrap;
        z-index: 2;
        grid-column: 2 / span 1;
        // background-color: rgba(0,0,0,.25);
        grid-row: 1;
        min-height: 400px;
        width: 100%;
        display: grid;
        grid-template-rows: 220px 1fr;
        grid-template-columns: minmax(300px, 360px) minmax(100px, 1fr);
        grid-template-areas:
            'menu background'
            'menu content';
        color: white;

        @media only screen and (max-width: 1000px) {
            grid-template-rows: auto 150px 1fr;
            grid-template-columns: minmax(300px, 1fr);
            grid-template-areas:
                'menu'
                'background'
                'content';
        }


        .menu {
            grid-area: menu;
            background-color: white;
            width: 100%;

            .inner-menu {
                // padding-left: 30px;
                padding-top: 30px;

                @media only screen and (max-width: 1000px) {
                    padding-bottom: 30px;
                }

                button {
                    padding-left: 30px;
                    border-left: 3px solid transparent;
                    padding-top: 5px;
                    padding-bottom: 5px;

                    &.active {
                        border-left: 3px solid $red;
                    }
                }
            }

            button {
                display: block;
                font-family: 'Verizon NHG DS';
                background-color: transparent;
                border: 0;
                font-size: 28px;
                margin-bottom: 20px;
                transition: font-weight .2s ease;
                color: black;

                @media only screen and (max-width:767px) {
                    font-size: 22px !important;
                }

                &.active,
                &:hover {
                    font-weight: bold;
                    transition: font-weight .2s ease;
                }
            }
        }

        .content {
            grid-area: content;
            width: 100%;
            position: relative;
            display: grid;
            grid-template-rows: 1fr;
            grid-template-columns: 1fr;

            .inner-content {
                // position: absolute;
                background-color: black;
                padding: 45px;
                opacity: 0;
                grid-column: 1 / span 2;
                grid-row: 2;
                transition: all .25s ease;
                pointer-events: none;

                &.active {
                    opacity: 1;
                    pointer-events: all;
                    transition: all .25s ease;
                }

                @media only screen and (max-width: 767px) {
                    padding: 15px;
                }
            }
        }

        @media only screen and (max-width: 1000px) {
            // grid-column: 2 / span 3;
            // grid-template-columns: minmax(290px, 300px) minmax(100px, 1fr);
            // grid-template-areas:
            // 'menu content content';
            // .content {
            //     grid-template-columns: 1fr 1fr;
            //    .inner-content {
            //         grid-column: 1 / span 2;
            //    }
            // }
        }

        @media only screen and (max-width: 767px) {
            // grid-template-columns: 15px minmax(100px, 1fr) 15px;
            // grid-template-areas:
            // '... menu ...' 
            // '... content ...';
            // padding-top: 30px;
            // .menu {
            //     padding-bottom: 30px;
            // }
        }
    }

    .background {
        grid-area: background;
        z-index: 0;
        // grid-column: 2;
        background-color: black;
        grid-row: 1;
        width: 100%;
        // min-height: 500px;
        height: 100%;
        display: grid;

        .inner-content {
            height: 100%;
            width: 100%;
            grid-column: 1 / span 1;
            grid-row: 1;
            background-size: cover;
            opacity: 0;
            transition: all .25s ease;
            background-position: top;

            &.active {
                opacity: 1;
                transition: all .25s ease;
            }
        }

        @media only screen and (max-width: 1000px) {
            // grid-column: 2/span 3;
            // grid-column: 1;
            grid-row: 2 / span 1;
        }
    }
}




//////////////////////
// Complete Journey
//////////////////////

.complete-journey {
    padding-bottom: 8%;
    background-color: black;
    justify-items: center;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: minmax(100px, 1fr) minmax(100px, 1380px) minmax(60px, 1fr);
    grid-template-areas:
        '... content ...';

    @media only screen and (max-width: 1200px) {
        grid-template-columns: minmax(70px, 1fr) minmax(100px, 1380px) minmax(30px, 1fr);
    }

    @media only screen and (max-width: 767px) {
        grid-template-columns: minmax(40px, 1fr) minmax(100px, 1380px) minmax(40px, 1fr);
    }

    .content {
        color: white;
        display: grid;
        grid-area: content;
        // background-color: #06AC4B;
        min-height: 40px;
        grid-template-rows: 1fr;
        grid-template-columns: 1fr;
        width: 100%;
        grid-template-areas:
            'inner-content';

        .top-indicators {
            display: grid;
            grid-gap: 10px;
            grid-template-columns: minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr);
            min-height: 30px;

            p {
                position: relative;
                transition: color .5s ease;
                color: #707070;

                &:after {
                    content: '';
                    position: absolute;
                    width: 0%;
                    height: 4px;
                    bottom: -10px;
                    left: 0;
                    background-color: var(--green);
                    transition: width 1s ease;
                }

                &:before {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 4px;
                    bottom: -10px;
                    left: 0;
                    background-color: #707070;
                }
            }

            div.phase {
                &.active {
                    p {
                        color: white;
                        transition: color .5s ease;

                        &:after {
                            width: 100%;
                            transition: width 1s ease;
                        }
                    }
                }

                @media only screen and (max-width: 1200px) {
                    &.mobile-active {
                        p {
                            color: white;
                            transition: color .5s ease;

                            &:after {
                                width: 100%;
                                transition: width 1s ease;
                            }
                        }
                    }
                }

            }

            div.phase.one {
                // grid-column: 1 / span 2;
                grid-area: phaseone;
                // @media only screen and (max-width: 1200px) { 
                //     grid-column: 1 / span 2;
                // }

            }

            div.phase.two {
                // grid-column: 3 / span 2;
                grid-area: phasetwo;


            }

            div.phase.three {
                // grid-column: 5/ span 1;
                grid-area: phasethree;

            }
        }

        .inner-content {
            display: grid;
            grid-gap: 10px;
            // grid-column: 1 / span 5;
            // grid-area: inner-content;
            grid-template-rows: 57px 70px 1fr;
            grid-template-columns: minmax(100px, auto) minmax(100px, auto) minmax(100px, auto) minmax(100px, auto) minmax(100px, auto);
            // background-color: #3506ac;
            min-height: 30px;
            grid-template-areas:
                'phaseone phaseone phasetwo phasetwo phasethree'
                'discover define deploy deliver evolve';

            p.indicators {
                position: relative;
                transition: color .5s ease;
                color: #707070;

                &:after {
                    content: '';
                    position: absolute;
                    width: 0%;
                    height: 4px;
                    bottom: -10px;
                    left: 0;
                    background-color: white;
                    transition: width 1s ease;
                }

                &:before {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 4px;
                    bottom: -10px;
                    left: 0;
                    background-color: #707070;
                }
            }

            div.phase {
                &.active {
                    p {
                        color: white;
                        transition: color .5s ease;

                        &:after {
                            width: 100%;
                            transition: width 1s ease;
                        }
                    }
                }

                @media only screen and (max-width: 1200px) {
                    &.mobile-active {
                        p {
                            color: white;
                            transition: color .5s ease;

                            &:after {
                                width: 100%;
                                transition: width 1s ease;
                            }
                        }
                    }
                }
            }

            div.phase.one {
                // grid-column: 1 / span 2;
                grid-area: phaseone;
                // @media only screen and (max-width: 1200px) { 
                //     grid-column: 1 / span 2;
                // }

            }

            div.phase.two {
                // grid-column: 3 / span 2;
                grid-area: phasetwo;

                @media only screen and (max-width: 1200px) {
                    grid-row: 6 / span 1;
                    // background-color: pink;
                }

            }

            div.phase.three {
                // grid-column: 5/ span 1;
                grid-area: phasethree;

                @media only screen and (max-width: 1200px) {
                    grid-row: 11 / span 1;
                }
            }

            @media only screen and (max-width: 1200px) {
                grid-template-rows: 60px 70px 1fr 70px 1fr 60px 70px 1fr 70px 1fr 60px 70px 1fr;
                grid-template-columns: minmax(100px, 1fr) minmax(100px, 1fr);
                grid-template-areas:
                    'phaseone phaseone'
                    'discover discover'
                    'define define'
                    'phasetwo phasetwo'
                    'deploy deploy'
                    'deliver deliver'
                    'phasethree phasethree'
                    'evolve evolve';
            }

            .card-wrap {
                display: grid;
                grid-gap: 10px;
                grid-template-rows: 70px 1fr;
                background-color: rgba(255, 255, 255, .15);
                height: 100%;
                flex-direction: column;
                align-items: center;

                // grid-columns:  minmax(100px, 500px)  minmax(100px, 500px)  minmax(100px, 500px)  minmax(100px, 500px)  minmax(100px, 500px);
                .title p {
                    @media only screen and (max-width: 767px) {
                        font-size: 24px;

                        &:after {
                            height: 18px;
                            width: 18px;
                            top: 8px;
                        }
                    }
                }
            }

            .title {
                grid-row: 1 / span 1;

                p {

                    position: relative;

                    &:after {
                        content: '';
                        height: 30px;
                        width: 30px;
                        position: absolute;
                        top: 0;
                        right: 0;
                        height: 100%;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                        background-image: url('../images/right-arrow.svg');
                        background-size: contain;
                        background-position: center;
                        background-repeat: no-repeat;
                    }

                }
            }

            .number {
                grid-row: 2 / span 1;
                display: grid;
                justify-content: center;

                @media only screen and (max-width: 1200px) {
                    grid-row: 2 / span 1;
                }
            }

            .panel {
                display: grid;
                grid-row: 3;
                grid-column: 1 / span 5;
                grid-template-columns: minmax(10px, 1fr) minmax(10px, 1fr) minmax(10px, 1fr) minmax(10px, 1fr) minmax(10px, 1fr);
                grid-gap: 10px;
                background-color: white;
                z-index: 9;
                clip-path: inset(0 100% 0 0);
                transition: clip-path 1s ease;
                pointer-events: none;
                grid-template-areas:
                    'panel-number panel-menu panel-menu panel-drawer panel-drawer';

                &.reveal {
                    clip-path: inset(0);
                    transition: clip-path 1s ease;
                    z-index: 10;
                    pointer-events: all;
                }

                @media only screen and (max-width: 1200px) {
                    &.mobile-reveal {
                        clip-path: inset(0);
                        transition: clip-path 1s ease;
                        z-index: 10;
                        pointer-events: all;
                    }
                }

                @media only screen and (max-width: 1200px) {
                    grid-template-areas:
                        'panel-number ...'
                        'panel-menu panel-menu'
                        'panel-drawer panel-drawer';
                }
            }

            .panel-number {
                display: grid;
                grid-area: panel-number;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                @media only screen and (max-width: 1200px) {
                    align-items: start;
                }
            }

            .discover-panel {
                @media only screen and (max-width: 1200px) {
                    grid-area: discovery-panel;
                    grid-row: 3 / span 1;
                    grid-column: 1 / span 2;
                    ;
                }

                .panel-drawer {
                    .drawer {
                        background-color: white;
                    }
                }
            }

            .define-panel {
                @media only screen and (max-width: 1200px) {
                    grid-area: define-panel;
                    grid-row: 5 / span 1;
                    grid-column: 1 / span 2;
                    ;
                }

                .panel-drawer {
                    .drawer {
                        background-color: white;
                    }
                }
            }

            .deploy-panel {
                @media only screen and (max-width: 1200px) {
                    grid-area: deploy-panel;
                    grid-row: 8 / span 1;
                    grid-column: 1 / span 2;
                    ;
                }

                .panel-drawer {
                    .drawer {
                        background-color: white;
                    }
                }
            }

            .deliver-panel {
                @media only screen and (max-width: 1200px) {
                    grid-area: define-panel;
                    grid-row: 10 / span 1;
                    grid-column: 1 / span 2;
                    ;
                }

                .panel-drawer {
                    .drawer {
                        background-color: white;
                    }
                }
            }

            .evolve-panel {
                @media only screen and (max-width: 1200px) {
                    grid-area: define-panel;
                    grid-row: 13 / span 1;
                    grid-column: 1 / span 2;
                    ;
                }

                .svg-container {
                    svg {
                        background-color: white;
                    }
                }
            }


            .panel-menu {
                grid-area: panel-menu;

                @media only screen and (max-width: 1200px) {
                    grid-column: 1 / span 5;
                    padding-left: 15px;
                    padding-right: 15px;
                    padding-bottom: 15px;
                }
            }

            .panel-drawer {
                grid-area: panel-drawer;

                @media only screen and (max-width: 1200px) {
                    grid-column: 1 / span 5;
                }
            }

        }
    }

    ////////////////////////////
    // Button and panel styles
    ////////////////////////////
    .inner-content {
        .card-wrap {
            border: 0;
            transition: all .25s ease;

            .title {
                width: 100%;
                align-items: start;

                p {
                    text-align: left;
                    color: white;
                    font-family: 'Verizon NHG DS';
                    font-weight: bold;
                    font-size: 36px;
                    transition: color .25s ease;
                    padding-left: 10px;
                }
            }

            h1.circle {
                color: white;
                font-family: 'Verizon NHG DS';
                font-weight: 400;
                font-size: 64px;
                border: 4px solid;
                height: 140px;
                width: 140px;
                line-height: 134px !important;
                border-radius: 100%;

                @media only screen and (max-width: 767px) {
                    height: 100px;
                    width: 100px;
                    line-height: 100px !important;
                }
            }

            &.discover {
                grid-area: discover;
                grid-row: 2 / span 2;

                @media only screen and (max-width: 1200px) {
                    grid-row: 2 / span 2;
                }

                .number h1 {
                    border-color: black;
                }
            }

            &.define {
                grid-area: define;
                grid-row: 2 / span 2;

                @media only screen and (max-width: 1200px) {
                    grid-row: 4 / span 2;
                }

                .number h1 {
                    border-color: black;
                }
            }

            &.deploy {
                grid-area: deploy;
                grid-row: 2 / span 2;

                @media only screen and (max-width: 1200px) {
                    grid-row: 7 / span 2;
                }

                .number h1 {
                    border-color: black;
                }
            }

            &.deliver {
                grid-area: deliver;
                grid-row: 2 / span 2;

                @media only screen and (max-width: 1200px) {
                    grid-row: 9 / span 2;
                }

                .number h1 {
                    border-color: black;
                }
            }

            &.evolve {
                grid-area: evolve;
                grid-row: 2 / span 2;

                // background-color: rgba(#00A543, .15)!important;
                @media only screen and (max-width: 1200px) {
                    grid-row: 12 / span 2;
                }
            }

            //////////////////////
            // Hover
            //////////////////////
            &:hover,
            &.reveal {
                background-color: white !important;
                transition: all .25s ease;

                &.discover {
                    .title p {
                        color: black;
                        transition: color .25s ease;

                        &:after {
                            background-image: url('../images/right-arrow-black.svg');
                        }
                    }

                    .number h1 {
                        background-color: black;
                    }
                }

                &.define {
                    .title p {
                        color: black;
                        transition: color .25s ease;

                        &:after {
                            background-image: url('../images/right-arrow-black.svg');
                        }
                    }

                    .number h1 {
                        background-color: black;
                    }
                }

                &.deploy {
                    .title p {
                        color: black;
                        transition: color .25s ease;

                        &:after {
                            background-image: url('../images/right-arrow-black.svg');
                        }
                    }

                    .number h1 {
                        background-color: black;
                    }
                }

                &.deliver {
                    .title p {
                        color: black;
                        transition: color .25s ease;

                        &:after {
                            background-image: url('../images/right-arrow-black.svg');
                        }
                    }

                    .number h1 {
                        background-color: black;
                    }
                }

                &.evolve {
                    // background-color: black!important;
                    color: black;

                    .title p {
                        color: black;

                        &:after {
                            background-image: url('../images/right-arrow-black.svg');
                        }
                    }

                    .number h1 {
                        background-color: black;
                    }
                }
            }

            @media only screen and (max-width: 1200px) {
                &.mobile-reveal {
                    background-color: white !important;
                    transition: all .25s ease;

                    &.discover {
                        .title p {
                            color: black;
                            transition: color .25s ease;

                            &:after {
                                background-image: url('../images/right-arrow-black.svg');
                            }
                        }

                        .number h1 {
                            background-color: black;
                        }
                    }

                    &.define {
                        .title p {
                            color: black;
                            transition: color .25s ease;

                            &:after {
                                background-image: url('../images/right-arrow-black.svg');
                            }
                        }

                        .number h1 {
                            background-color: black;
                        }
                    }

                    &.deploy {
                        .title p {
                            color: black;
                            transition: color .25s ease;

                            &:after {
                                background-image: url('../images/right-arrow-black.svg');
                            }
                        }

                        .number h1 {
                            background-color: black;
                        }
                    }

                    &.deliver {
                        .title p {
                            color: black;
                            transition: color .25s ease;

                            &:after {
                                background-image: url('../images/right-arrow-black.svg');
                            }
                        }

                        .number h1 {
                            background-color: black;
                        }
                    }

                    &.evolve {

                        // background-color: var(--green)!important;
                        .title p {
                            color: black;

                            &:after {
                                background-image: url('../images/right-arrow.svg');
                            }
                        }
                    }
                }
            }
        }
    }

    //////////////////////////
    // Lower Drawers /Panels
    //////////////////////////
    .panel {
        .panel-number {
            @media only screen and (max-width: 767px) {
                grid-column: 1 / span 2 !important;
            }

            h1.main {
                font-family: 'Verizon NHG DS';
                font-weight: 400;
                font-size: 64px;
                border: 4px solid;
                height: 140px;
                width: 140px;
                line-height: 134px !important;
                border-radius: 100%;
                text-align: center;

                @media only screen and (max-width: 1200px) {
                    height: 100px;
                    width: 100px;
                    line-height: 100px !important;
                    margin-top: 15px;
                    margin-bottom: 15px;
                }
            }
        }

        &.discover-panel {
            h1.main {
                border-color: black;
                background-color: black;
            }

            // .panel-drawer {
            //     .drawer {
            //         background-color: var(--orange);
            //     }
            // }
        }

        &.define-panel {
            h1.main {
                border-color: black;
                background-color: black;
            }
        }

        &.deploy-panel {
            h1.main {
                border-color: black;
                background-color: black;
            }
        }

        &.deliver-panel {
            h1.main {
                border-color: black;
                background-color: black;
            }
        }

        &.evolve-panel {

            // background-color: var(--green)!important;
            h1.main {
                border-color: black !important;
                color: white;
                background-color: black;
            }
        }

        .panel-menu {
            justify-content: center;
            align-items: center;
            align-content: center;
            flex-direction: column;
            display: flex;

            button {
                font-family: 'Verizon NHG DS';
                font-weight: 400;
                font-size: 20px;
                position: relative;
                text-align: left;
                display: block;
                background-color: transparent;
                border: 0;
                width: 100%;
                padding-bottom: 15px;
                padding-top: 15px;
                margin-top: 5px;
                color: black;
                transition: color .25s ease;

                @media only screen and (max-width: 767px) {
                    font-size: 18px;
                }

                &:before {
                    content: '';
                    position: absolute;
                    bottom: -5px;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    background-color: #808080;
                }

                &:after {
                    content: '';
                    position: absolute;
                    bottom: -5px;
                    left: 0;
                    width: 0;
                    height: 2px;
                    background-color: black;
                    transition: width .5s ease;
                }

                span {
                    width: 92%;
                    display: block;
                    position: relative;

                    &:after {
                        content: '';
                        position: absolute;
                        height: 24px;
                        width: 20px;
                        background: url('../images/arrow-right_gray.svg');
                        background-size: contain;
                        background-repeat: no-repeat;
                        right: -8px;
                        bottom: -1px;
                        margin-right: -8%;
                    }
                }

                &:hover,
                &.active {
                    font-weight: bold;
                    color: black;
                    transition: color .25s ease;

                    &:after {
                        width: 100%;
                        transition: width .5s ease;
                        background-repeat: no-repeat;
                    }

                    span {
                        &:after {
                            content: '';
                            height: 24px;
                            width: 20px;
                            background: url('../images/arrow-right_blk.svg');
                            background-size: contain;
                            background-repeat: no-repeat;
                            right: -8px;
                            bottom: -1px;
                        }
                    }
                }
            }
        }

        &.evolve-panel {
            .panel-menu {
                button {
                    color: black;

                    &:before {
                        content: '';
                        background-color: white;
                    }

                    &:after {
                        content: '';
                        background-color: black;
                    }

                    span {
                        &:after {
                            height: 24px;
                            width: 20px;
                            background-size: contain;
                            background: url('../images/arrow-right_wht.svg');
                            background-repeat: no-repeat;
                        }
                    }

                    &:hover,
                    &.active {
                        color: black;
                        transition: color .25s ease;

                        &:after {
                            width: 100%;
                            transition: width .5s ease;
                        }

                        span {
                            &:after {
                                content: '';
                                height: 24px;
                                width: 20px;
                                background-size: contain !important;
                                background: url('../images/arrow-right_blk.svg');
                                background-repeat: no-repeat;
                            }
                        }
                    }
                }
            }
        }

        // Drawer
        .panel-drawer {
            display: grid;
            grid-row: 1 / span 1;
            grid-column: 1;
            grid-template-columns: 1fr;
            grid-template-rows: 1fr;
            grid-template-areas:
                'drawer';
            position: relative;

            img {
                z-index: 0;
                position: relative;
            }

            .drawer-bg {
                grid-area: drawer;
                background-color: white;
            }

            .svg-container {
                width: 100%;
                max-width: 460px;
                display: flex;
                align-items: center;
                justify-content: center;

                svg {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                }
            }

            .close-btn {
                position: absolute;
                height: 40px;
                width: 40px;
                background: white;
                z-index: 10;
                right: 4px;
                top: 4px;
                opacity: 0;
                pointer-events: none;
                transition: all .25s ease;
                transition-delay: 0;
                border: 0;

                &.active {
                    opacity: 1;
                    pointer-events: all;
                    transition: all .25s ease;
                    transition-delay: .5s;
                }

                svg {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);

                    path {
                        transition: fill .25s ease;
                    }
                }

                &:hover {
                    background: white;

                    // svg {
                    //     path {
                    //         fill: var(--green)!important;
                    //         transition: fill .25s ease;
                    //     }
                    // }
                }
            }

            .drawer {
                z-index: 1;
                position: relative;
                background-color: white;
                grid-area: drawer;
                clip-path: inset(0 100% 0 0);
                transition: clip-path 1s ease;
                grid-row: 1 / span 1;
                // width: 100%;
                // height: 100%;
                padding: 40px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-content: center;

                &.active {
                    z-index: 2;
                    clip-path: inset(0);
                    transition: clip-path 1s ease;
                }

                p {
                    font-size: 14px;
                    font-weight: 400;
                    margin-bottom: 5px;
                    margin-top: 5px;
                    color: black;

                    &.title {
                        font-weight: bold;
                        font-size: 18px;
                        width: 100%;

                    }
                }

                // Drawer Content
                .inner-row {
                    display: flex;
                    flex-direction: column;

                    .row {
                        display: flex;

                        .left {
                            margin-right: 30px;
                            flex: 1;
                        }

                        .right {
                            flex: 1;
                        }
                    }

                    p {}
                }
            }
        }
    }


}


//////////////////////////
// Lower Indicators
//////////////////////////
section.lower-indicators {
    background-color: black;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: minmax(100px, 1fr) minmax(100px, 1380px) minmax(60px, 1fr);
    grid-template-areas:
        '... indicatorwrap ...';
    padding-top: 30px;
    padding-bottom: 50px;

    @media only screen and (max-width: 1200px) {
        grid-template-columns: minmax(70px, 1fr) minmax(100px, 1380px) minmax(30px, 1fr);
    }

    @media only screen and (max-width: 767px) {
        grid-template-columns: minmax(40px, 1fr) minmax(100px, 1380px) minmax(40px, 1fr);
    }

    .indicator-wrap {
        grid-area: indicatorwrap;

        .pure-success {
            color: var(--green);

            span {
                width: 0;
                height: 8px;
                background-color: var(--green);
                display: block;
                border-radius: 2px;
            }

            &.activated {
                span {
                    width: 100%;
                    transition: width 2s ease;
                }
            }
        }

        .typical {
            color: #747677;

            span {
                width: 0;
                height: 4px;
                background-color: #747677;
                display: block;
                border-radius: 2px;
            }

            &.activated {
                span {
                    width: 65%;
                    transition: width 3s ease;
                }
            }
        }

        .lower-panel {
            display: flex;
            color: white;
            flex-wrap: wrap;
            justify-content: space-between;

            p {
                font-size: 24px;
                font-weight: 400;
            }

            &:last-child {
                display: flex;
                align-items: center;
            }
        }
    }
}


//////////////////////////
// Complete Empathy
//////////////////////////

.complete-empathy {
    justify-items: left;
    display: grid;
    // grid-gap: 30px;
    grid-template-rows: 80px 1fr 80px;
    grid-template-columns: minmax(100px, 1fr) 40px minmax(100px, 820px) minmax(100px, 380px) 40px minmax(100px, 120px) minmax(60px, 1fr);
    // grid-auto-rows: 680px;
    grid-template-areas:
        '... background background background background ... ...';
    padding-top: 5%;
    padding-bottom: 6%;

    @media only screen and (max-width: 767px) {
        grid-template-columns: minmax(30px, 1fr) 40px minmax(50px, 820px) minmax(50px, 380px) minmax(50px, 120px) 40px minmax(30px, 1fr);
        grid-template-areas:
            '... background background background background background ...';
    }

    .background {
        grid-area: background;
        grid-row: 1 / span 4;
        height: 100%;
        width: 100%;
        background-position: center;
    }

    .content {
        grid-row: 2 /span 1;
        grid-column: 4 / span 3;
        background-color: black;
        padding-top: 8%;
        padding-bottom: 8%;
        padding-left: 10%;
        padding-right: 10%;
        color: white;
        z-index: 10;

        h1 {
            margin: 0;
        }
    }

    @media only screen and (max-width: 767px) {
        .content {
            grid-column: 3 / span 3;
            // background-color: rgba(black, .75);
        }
    }
}



//////////////////////////
// Case studies
//////////////////////////

.case-studies {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: minmax(100px, 1fr) minmax(100px, 1380px) minmax(60px, 1fr);
    background-color: white;
    padding-top: 5%;
    padding-bottom: 8%;

    @media only screen and (max-width: 767px) {
        grid-template-columns: minmax(30px, 1fr) minmax(100px, 1380px) minmax(30px, 1fr);
    }

    h1.title {
        grid-column: 2 / span 1;
        color: black;
        // border-bottom: 2px solid black;
        // padding-bottom: 30px;
        margin-bottom: 25px;
    }

    span.bottom-border {
        margin-bottom: 30px;
        height: 2px;
        background-color: black;
        width: 100%;
        grid-column: 2 / span 1;
    }

    div.wrap {
        opacity: 0;

        &.active {
            opacity: 1;
            transition: opacity 1s ease;
        }
    }

    .content {
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: minmax(100px, 430px) minmax(100px, 430px) minmax(100px, 430px);
        grid-gap: 60px;
        grid-column: 2 / span 1;
        color: black;

        h1 {
            font-size: 24px !important;
            font-weight: bold;
            line-height: 27px !important;
        }

        p {
            font-size: 16px;
            font-weight: 400;
            margin-bottom: 30px;
        }

        div {
            div.flex {
                display: flex;
                flex-direction: column;
                margin-top: auto;

                div {}
            }
        }

        div.one {
            grid-column: 1;
            display: flex;
            flex-direction: column;
        }

        div.two {
            grid-column: 2;
            display: flex;
            flex-direction: column;
        }

        div.three {
            grid-column: 3;
            display: flex;
            flex-direction: column;
        }

        @media only screen and (max-width: 767px) {
            grid-template-columns: 1fr;

            div.one {
                grid-column: 1;
            }

            div.two {
                grid-column: 1;
            }

            div.three {
                grid-column: 1;
            }
        }

    }

}



//////////////////////////
// Get Started
//////////////////////////

.get-started {
    padding-top: 5%;
    padding-bottom: 15%;
    min-height: 500px;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: minmax(100px, 1fr) 40px minmax(100px, 1380px) minmax(60px, 1fr);
    background-color: black;

    // padding-bottom: 300px;
    @media only screen and (max-width: 767px) {
        grid-template-columns: minmax(30px, 1fr) 40px minmax(100px, 1380px) minmax(30px, 1fr);
    }

    p {
        font-size: 24px;
        padding-bottom: 30px;
        max-width: 80%;

        @media only screen and (max-width: 767px) {
            font-size: 20px;
            max-width: 100%;
        }
    }

    h3 {
        font-weight: bold;
        color: white;
        font-size: 26px;
        font-family: 'Verizon NHG DS';
        margin-bottom: 0px;

        @media only screen and (max-width: 767px) {
            font-size: 22px;
        }
    }

    .text {
        grid-column: 3;
        color: white;

        @media only screen and (max-width: 767px) {
            grid-column: 2 / span 2;
        }
    }

    .form {
        grid-column: 3;
        font-family: 'Verizon NHG DS';

        @media only screen and (max-width: 767px) {
            grid-column: 2 / span 2;
        }
    }

    .row {
        display: flex;

        @media only screen and (max-width: 767px) {
            flex-wrap: wrap;
        }

        .col:first-of-type {
            margin-right: 30px;

            @media only screen and (max-width: 767px) {
                margin-right: 0;
            }
        }

        .col {
            width: 50%;
            margin-bottom: 25px;
            position: relative;

            @media only screen and (max-width: 767px) {
                width: 100%;
            }

            label {
                color: white;
                padding-bottom: 10px;
                display: block;
                letter-spacing: .03em;
                font-size: 16px;
            }

            input,
            select {
                // width: 100%;
                background-color: black;
                border: 0;
                height: 36px;
                color: white;
                padding-left: 10px;
                // padding-right: 10px;
                display: block;
                width: -moz-available;
                /* WebKit-based browsers will ignore this. */
                width: -webkit-fill-available;
                /* Mozilla-based browsers will ignore this. */
                width: fill-available;
                border-top: 1px solid gray;
                border-right: 1px solid gray;
                border-left: 1px solid gray;
                border-bottom: 1px solid white;
                -webkit-appearance: none !important;

                // background-image: url(../images/right-arrow-blue.svg)!important;
                // background: url(../images/right-arrow-blue.svg)!important;
                &:focus {
                    outline: none;
                }

                &:focus-visible {
                    outline: 1px solid white;
                }
            }

            select {
                padding: 5px 10px;
                height: 40px;
                -webkit-appearance: none;
                -moz-appearance: none;
                background-image: url('../images/arrow-down_wht.svg');
                background-repeat: no-repeat;
                background-position-x: 98%;
                background-size: 6%;
                background-position-y: 9px;
            }

            input.white-btn {
                display: flex;
                width: 160px;
                height: 50px;
                padding: 0;
                text-align: center;
                justify-content: center;

                &:hover {
                    color: black;
                    background: white;
                }
            }

            input:-internal-autofill-selected {
                background-color: black !important;
                color: white !important;
            }

            input:-webkit-autofill,
            select:-webkit-autofill,
            input:-webkit-autofill:hover,
            select:-webkit-autofill:hover,
            input:-webkit-autofill:focus,
            select:-webkit-autofill:focus,
            input:-webkit-autofill:active,
            select:-webkit-autofill:active {
                -webkit-box-shadow: 0 0 0 30px black inset !important;
                -webkit-text-fill-color: white !important;
                -webkit-appearance: none !important;
            }

            input.accepted {
                border-bottom: 2px solid $green;
                background: transparent !important;
                padding-right: 30px;

                &+span {
                    background-image: url('../images/approved.svg') !important;
                    background-repeat: no-repeat !important;
                    background-color: transparent !important;
                    background-position: center !important;
                    -webkit-appearance: none !important;
                    background-size: contain;
                    float: right;
                    margin-top: -30px;
                    margin-right: 5px;
                    width: 30px;
                    height: 20px;
                    display: inline-block;
                }
            }

            input.unaccepted {
                border-bottom: 2px solid $red;
                border-top: 1px solid $red;
                border-right: 1px solid $red;
                border-left: 1px solid $red;
                padding-right: 30px;

                &+span {
                    background-image: url('../images/unapproved.svg') !important;
                    background-repeat: no-repeat !important;
                    background-color: transparent !important;
                    background-position: center !important;
                    -webkit-appearance: none !important;
                    background-size: contain;
                    float: right;
                    margin-top: -30px;
                    margin-right: 5px;
                    width: 30px;
                    height: 20px;
                    display: inline-block;
                }
            }

        }
    }


}



input.error,
select.error,
textarea.error {
    outline: none;
    border-color: red;
    border-width: 1px;
    border-style: solid;

    &:valid {
        border-style: auto;
        border-color: inherit;
    }
}


#form-submit.disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: .5;
}

.section.get-started {

.submitted {
    display: none;
}
.before-submit {
    display: block;
}

&.submitted {
    .submitted {
        display: block;
    }
    .before-submit {
        display: none;
    }
}

    #eloqExternal {
        .elq-form {
            padding: 0 !important;
            background-color: black !important;

            .flex.container-fluid {
                &>:not(p) {
                    background-color: black !important;
                }

                input,
                select,
                textarea {
                    border-radius: 0 !important;
                    background-color: black;
                    // background: black;
                    color: white !important;

                    &.valid {
                        border-color: $green;
                    }

                    &::-webkit-input-placeholder {
                        /* WebKit, Blink, Edge */
                        color: white !important;
                    }

                    &:-moz-placeholder {
                        /* Mozilla Firefox 4 to 18 */
                        color: white !important;
                        opacity: 1;
                    }

                    &::-moz-placeholder {
                        /* Mozilla Firefox 19+ */
                        color: white !important;
                        opacity: 1;
                    }

                    &:-ms-input-placeholder {
                        /* Internet Explorer 10-11 */
                        color: white !important;
                    }

                    ::placeholder {
                        /* Most modern browsers support this now. */
                        color: white !important;
                    }
                }
                .submit-button-style {
                    border-radius: 100px!important;
                    width: 250px!important;
                    flex: none!important;
                    background-color: black!important;
                    &:hover {
                        background-color: white!important;
                        background: white!important;
                        color: black!important;
                    }
                }
            }
        }
    }
}

// Healthcare
.healthcare {
    #go-to-hp-btn { 
        margin-right: 12px;
    }
    @media (max-width: 767px) {
        #go-to-hp-btn {
            display: none;
        }
        .masthead .bg-grad {
            background-color: rgba(0, 0, 0, 0.6);
        }
    }
    .masthead {
        @media (min-width: 1024px) {
            p {
                color: white;
                max-width: 50%;
                opacity: 0;
            }
        }
    }
    .complete-solution {
        background: #fff;
    }
    .complete-solution .content-wrap .menu {
        background: #000;
        @media (min-width: 1000px) {
            .inner-menu {
                position: relative;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
    .complete-solution .content-wrap .menu button {
        color: #fff;
    }
    .section.complete-journey {
        padding-top: 5%;
    }
    .complete-empathy {
        padding-bottom: 5%;
    }
}
